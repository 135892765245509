import React, { FC, useEffect, useState } from 'react';
import {
  WidgetProps,
  useBi,
  useTranslation,
  useEnvironment,
} from '@wix/yoshi-flow-editor';
import { classes } from './Widget.st.css';
import { IWidgetControllerConfig } from '@wix/native-components-infra/dist/src/types/types';
import { photoAlbumGalleryLoaded } from '@wix/bi-logger-photo-ugc/v2';
import { CoverBackgroundOpacityFix } from '../../../services/ssrErrorService';
import { useBuilderListener } from '@wix/albums-uou-common';

export type ControllerProps = {
  name: string;
  cssBaseUrl: string;
  locale: string;
  mobile: boolean;
  instance: string;
  instanceId: string;
  time: number;
  reportPageLoadedTriggered: number;
  currentPage?: string;
  refreshSets: (data: any, options?: any) => void;
  goToPage: (path: string) => void;
  shareUrl: { url: string };
  errorFetchingDataOnSSr: boolean | undefined;
};

const Widget: FC<WidgetProps<ControllerProps>> = (props) => {
  const {
    refreshSets,
    goToPage,
    instance,
    instanceId,
    time: timeFromProps,
    currentPage,
    shareUrl,
    errorFetchingDataOnSSr,
    reportPageLoadedTriggered,
  } = props;
  const bi = useBi();
  const { t } = useTranslation();
  const { isPreview } = useEnvironment();
  useEffect(() => {
    if (shareUrl) {
      window.open(
        `mailto:?body=${t('ALBUM_SHARE_MAILTO_BODY', {
          shareUrl: shareUrl.url,
        })}&subject=${t('ALBUM_SHARE_MAILTO_SUBJECT')}`,
        '_blank',
      );
    }
  }, [shareUrl, t]);

  useBuilderListener({
    currentPage,
    timeFromProps,
    reportPageLoaded: (loadingTime: number) => {
      bi.report(
        photoAlbumGalleryLoaded({
          instance_id: instanceId,
          loading_time: loadingTime,
        }),
      );
    },
    instance,
    instanceId,
    enable: isPreview,
    onNavigateRequest: goToPage,
    onRefreshData: refreshSets,
  });

  return (
    <div className={classes.header}>
      {errorFetchingDataOnSSr && <CoverBackgroundOpacityFix />}
      <h2 className={classes.h2}>Albums Gallery Place holder</h2>
    </div>
  );
};

export default Widget;
