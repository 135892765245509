import React from 'react';

export const CoverBackgroundOpacityFix = () => (
  <style type="text/css">
    {`#bgMedia_pageBackground_nqtt5 ,#bgMedia_pageBackground_c1dmp{
  --fill-layer-image-opacity: 1 !important;
}
`}
  </style>
);
